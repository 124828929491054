import './LoginContainer.css'
import logo from '../assets/scybook.png'
import {
    useEffect,
    useRef,
    useState
} from 'react'
import { useNavigate } from 'react-router';
import userStore from '../zustand/user.store';

export default function LoginContainer(props: {type: string}) {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    let navigate = useNavigate();
    const [fullName, setFullName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [isLogin, setIsLogin] = useState<boolean>();

    const us = userStore();
    const ls = localStorage;

    return (
        <div className='LoginContainer'>
            <div className='loginDiv'>
                <img src={logo} alt="logo"/>
                <p className='title'>{props.type}</p>
                {props.type === 'SIGN IN' ? 
                <div className='info'>
                    <p>Log into your account</p>
                    <input placeholder='Email*' className='blue ' onChange={(e) => setEmail(e.target.value)}/>
                    <input placeholder='Password*' className='blue ' style={{marginTop: '24px'}} onChange={(e) => setPassword(e.target.value)} type='password'/>
                    <button className='blue btn' onClick={() => {
                        fetch('https://scybook-api.onrender.com/user/login', {
                            method: "POST",
                            body: JSON.stringify({
                                "email": email,
                                "password": password
                            })
                        }).then(res => res.json().then(async data => {
                            if(data.isLogin == true) {
                                ls.setItem("id", await data.id)
                                ls.setItem("phone", await data.phone)
                                ls.setItem("CPF", await data.cpf)
                                ls.setItem("password", await data.password)
                                ls.setItem("educationLevel", await data.educationLevel.toString())
                                setIsLogin(await data.isLogin)
                            }
                            
                        }))
                        isLogin ? navigate("/home") : window.alert("Usuário não encontrado")


                        }}>{props.type}</button>
                    <div className="a"><p className="redirect" onClick={() => {navigate('/register')}}>Don't have an account?</p></div>
                </div>
                : 
                <div className='info'>
                    <p>Register your account</p>
                    <input placeholder='Full Name*' className='blue ' onChange={(e) => setFullName(e.target.value)}/>
                    <input placeholder='Email*' className='blue ' style={{marginTop: '24px'}} onChange={(e) => setEmail(e.target.value)}/>
                    <button className='blue btn' onClick={() => {
                        navigate("/step1")
                        email && ls.setItem('email', email)
                        fullName && ls.setItem('name', fullName)
                }}>{props.type}</button>
                    <div className="a"><p className="redirect" onClick={() => {
                        navigate('/');
                        email && ls.setItem('email', email)
                        fullName && ls.setItem('name', fullName)
                        }}>Already have an account?</p></div>
                </div>
                }
                <a href='https://docs.google.com/document/d/1X_y6VLR-wIYB_dY1TuuP1Kta2m5C7tO0k3A1pzpxpQM/edit?usp=sharing'>Our terms of use</a>
            </div>  
        </div>
    )
}