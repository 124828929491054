import { useEffect, useState } from 'react'
import './Home.css'
import Header from '../Header/Header';
import Category from '../Category/Category';
import Button from '../Button/Button';
import eye from '../assets/eye.png'
import ArticlePreview from '../ArticlePreview/ArticlePreview';
import { useNavigate } from 'react-router';

export default function Home() {

    const [articles, setArticles] = useState<article[]>();
    const [categories, setCategories] = useState<category[]>();
    const [recommendations, setRecommendations] = useState<user[]>();
    const [filter, setFilter] = useState<number>();

    var navigate = useNavigate()

    var ls = localStorage;

    interface article {
        title: string;
        description: string;
        doi: string;
        issn?: string;
        fk_user: number;
        data_criacao: Date;
        user: user;
        vizualization: number;
    }

    interface category {
        id: number,
        name: string
    }

    interface user {
        pk_id: number;
        email: string;
        password: string;
        name: string;
        phone: string;
        fk_education_level: number;
        cpf: string;
      }

    useEffect(() => {
        fetch(`https://scybook-api.onrender.com/user/recommend/7`).then(res => res.json().then(async (data: article[]) => {
            setArticles(data)
        }))

        fetch('https://scybook-api.onrender.com/category').then(res => res.json().then(data => {
            setCategories(data)
        }))

        //${ls.getItem("id")}
        fetch(`https://scybook-api.onrender.com/user/recommend/people/7`).then(res => res.json().then(data => {
            setRecommendations(data)
        }))
    }, [])

    return (
        <div className='home'>
            <Header/>
            <div className="content">
            <aside className='left'>
                {categories && categories.map(el => (
                    <a onClick={() => setFilter(el.id)}><Category id={el.id} text={el.name} shouldSwitch={false}/></a>
                ))}
            </aside>
            <div className="artigos">
                {articles && articles.map((el: article) => (
                    <ArticlePreview author={el.user.name} date={el.data_criacao} desc={el.description} img={eye} title={el.title} views={el.vizualization}/>
                ))}
            </div>
            <aside className='right'>
                <button className='buttonAa abc'>Create Group</button>
                {/* <div className="recom">
                    <p style={{fontSize: "12px", marginBottom: '10px'}}>Recommended Poeple</p>
                    {ls.getItem('id') && recommendations?.map((el: user) => (
                        <p style={{fontSize: '12px'}}>@{el.name}</p>
                    ))}
                </div> */}
                <button className='buttonAa'>POST</button>
            </aside>
        </div> 
              
        </div>
    )
}