import usercaterogyStore from '../zustand/usercaterogy.store';
import './Category.css';
import { useState } from 'react';

export default function Category(props: {text: string, id: number, shouldSwitch?: boolean}) {
    const [color, setcolor] = useState("#262D6B");
    const [background, setbackground] = useState("#FBFBFB");

    const TrocarCategory = () => {
        if(props.shouldSwitch !== false) {
            if (color == "#FBFBFB"){
                setcolor("#262D6B");
                setbackground("#FBFBFB")
            } else {
                setcolor("#FBFBFB");
                setbackground("#262D6B");                        
            }
        }
        
    };
    
    return (
        <button className="category" onClick={TrocarCategory} style={{color:color, background: background}}>{props.text}</button> 
    )
}