import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from './Login/Login';
import Home from './Home/Home';
import RegisterContainer from './RegisterContainer/RegisterContainer';
import Input from './Input/Input';
import correct from './assets/correct.png';
import Button from './Button/Button';
import arrow from './assets/arrow.png';
import Category from './Category/Category';
import step1 from './assets/Steps1.png';
import step2 from './assets/Steps2.png';
import step3 from './assets/Steps3.png';
import userStore from './zustand/user.store';
import Profile from './Profile/Profile'
import { log } from 'console';

function App() {
  const [CPF, setCPF] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [City, setCity] = useState<string>();
  const [state, setState] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [cpassword, setcPassword] = useState<string>();
  const [type, setType] = useState<string>("Type*");
  const [educationLevel, setEducationLevel] = useState<number>();
  const [institution, setInstitution] = useState<number>();
  const [gradYear, setGradYear] = useState<string>();
  const [position, setPosition] = useState<string>();
  const [division, setDivision] = useState<string>();
  const [ORCID, setORCID] = useState<string>();
  const [isStudent, setIsStudent] = useState<boolean>();
  const [typeText, setTypeText] = useState<string>("Type*");
  const [educationLevelText, setEducationLevelText] = useState<string>("Education level*");
  const [institutionText, setInstitutionText] = useState<string>("institution*");

  const [tOpened, setTOpened] = useState<boolean>(false);
  const [eOpened, setEOpened] = useState<boolean>(false);
  const [iOpened, setIOpened] = useState<boolean>(false);

  const [types, setTypes] = useState<institutionType[]>();
  const [edLevels, setEdLevels] = useState<educationLevel[]>();
  const [institutions, setInstitutions] = useState<institution[]>(); 
  const [categories, setCategories] = useState<category[]>();

  var arr: number[] = []
  
  const ls = localStorage;

  const us = userStore();

  useEffect(() => {
    if(phone != null && phone != "") {
      ls.setItem("phone", phone)
    }
    if(CPF != null && CPF != "") {
      ls.setItem("CPF", CPF)
    }
    if(password != null && password != "") {
      ls.setItem("password", password)
    }
    if(educationLevel && educationLevel != null) {
      ls.setItem("educationLevel", educationLevel.toString())
    }
    if(ORCID && ORCID != null && ORCID != "") {
      ls.setItem("ORCID", ORCID)
    }

    {isStudent && ls.setItem('isStudent', `${isStudent}`)}

    if(gradYear && gradYear!= null) {
      ls.setItem('gradYear', gradYear)
    }

    if(institution && institution != null) {
      ls.setItem('institution', institution.toString())
    }

    if(division && division != null) {
      ls.setItem('division', division.toString())
    }

  }, [phone, CPF, password, educationLevel, ORCID, isStudent, gradYear, institution, division])

  const register = () => {
    var data: object = {
        "name": ls.getItem("name"),
        "email": ls.getItem("email"),
        "phone": ls.getItem("phone"),
        "cpf": ls.getItem("CPF"),
        "password": ls.getItem("password"),
        "fk_education_level": ls.getItem("educationLevel")
    }

    fetch('https://scybook-api.onrender.com/user', {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => res.json().then(data => {
      console.log(data)
      ls.setItem('id', data.pk_id)
    }))
  }

  interface category {
    pk_id: number,
    name: string
  }

  interface institutionType {
    pk_id: number,
    name: string
  }

  interface educationLevel {
    pk_id: number,
    name: string
  }

  interface institution {
    pk_id: number,
    name: string,
    fk_institution_type: number
  }

  const [logged, setLogged] = useState<boolean>();

  useEffect(() => {
    localStorage.getItem("id") == undefined || localStorage.getItem('id') == null ?
      setLogged(false)
      : 
      setLogged(true)

      fetch('https://scybook-api.onrender.com/institution-type').then(res => res.json().then(data => setTypes(data)));
      fetch('https://scybook-api.onrender.com/education-level').then(res => res.json().then(data => setEdLevels(data)));
      fetch('https://scybook-api.onrender.com/institution').then(res => res.json().then(data => setInstitutions(data)));
      fetch('https://scybook-api.onrender.com/category').then(res => res.json().then(data => setCategories(data)))
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login isLogin={true}></Login>}/>
        <Route path='/register' element={<Login isLogin={false}></Login>}></Route>
          <Route path='/step1' element={<RegisterContainer next={'/step2'} previous='/' img={step1} title='YOUR DATA' content={
            <>
              <div className='mankey'>
                <p>Enter your personal data</p>
              </div>
              <div className='row'>
                <div className='column'>
                  <input className='input' placeholder='Phone*' type='text' onChange={(e) => setPhone(e.target.value)}/>
                  <input className='input' placeholder='City*' type='text' onChange={(e) => setCity(e.target.value)}/>
                  <input className='input' placeholder='Password*' type='password' onChange={(e) => {setPassword(e.target.value); setPassword(e.target.value)}}/>
                </div>
                <div className='column'>
                  <input className='input' placeholder='CPF*' type='text' onChange={(e) => setCPF(e.target.value)}/>
                  <input className='input' placeholder='State*' type='text' onChange={(e) => setState(e.target.value)}/>
                  <input className='input' placeholder='Confirm password*' type='password' onChange={(e) => setcPassword(e.target.value)}/>
                </div>
              </div>
              <div className='mankey2'>
                {password !== undefined && password !== '' ? 
                  <div className='row'>
                    <div className="column">
                      
                      {password && password.length < 8 ? <p style={{color: '#CC2828'}}>X 8 characters</p>: <div className='row2'><img src={correct}/> <p style={{color: "#2BD800"}}> 8 caracteres</p></div>}
                      {password && !/[A-Z]/.test(password) ? <p style={{color: '#CC2828'}}>X 1 uppercase character</p>: <div className='row2'><img src={correct}/> <p style={{color: "#2BD800"}}> 1 letra maiúscula</p></div>}
                      {password && !/[a-z]/.test(password) ? <p style={{color: '#CC2828'}}>X 1 lowercase character</p>: <div className='row2'><img src={correct}/> <p style={{color: "#2BD800"}}> 1 letra minúscula</p></div>}
                    </div>
                    <div className="column">
                      {password && !/[0-9]/.test(password) === true ? <p style={{color: '#CC2828'}}>X 1 numerical character</p>: <div className='row2'><img src={correct}/><p style={{color: "#2BD800"}}> 1 número</p></div>}
                      {password && !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) ? <p style={{color: '#CC2828'}}>X 1 caractere especial</p>: <div className='row2'><img src={correct}/> <p style={{color: "#2BD800"}}> 1 caractere especial</p></div>}
                    </div>
                  </div>
                  :
                  <div className='row'>
                  <div className="column">
                    
                    <p style={{color: '#CC2828'}}>X 8 characters</p>
                    <p style={{color: '#CC2828'}}>X 1 uppercase character</p>
                    <p style={{color: '#CC2828'}}>X 1 lowercase character</p>
                  </div>
                  <div className="column">
                    <p style={{color: '#CC2828'}}>X 1 numerical character</p>
                    <p style={{color: '#CC2828'}}>X 1 special character</p>
                  </div> 
                </div>
                }
                {
                password && cpassword && password !== cpassword && 
                <>
                  <p style={{color: "#CC2828"}}> Passwords don't match</p>
                </>
                }
              </div>
            </>
          }/>}/>
        <Route path='/step2' element={<RegisterContainer next='/step2' title='EDUCATION' previous='/step1' img={step2} shouldAppear={false} content={
          <>
            <h1 style={{fontWeight: 'bold'}}>You are a</h1>
            <div className="row">
              <div className="square">
                <h1>Student</h1>
                <a className='btnmacaco' href='step2-1' onClick={() => setIsStudent(true)}>
                  NEXT
                </a>
              </div>
              <div className='verticalRow'></div>
              <div className="square">
                <h1>Professional</h1>
                <a className='btnmacaco' href="step2-2" onClick={() => setIsStudent(false)}>
                  NEXT
                </a>
              </div>
            </div>
          </>
        }/>}/>
        <Route path='/step2-1' element={<RegisterContainer next='/step3' img={step2} previous={'/step2'} title='EDUCATION' content={
          <>
            <div className='mankey'><p className='aaa' style={{fontWeight: "bold"}}>Enter your details about your education level</p></div>
            <div className="row">
              <div className="column">                
                <div className="node">
                  <div className='input2' onClick={() => setIOpened(!iOpened)}>{institutionText} <img src={arrow} className='arrow' style={{transform: iOpened ? "rotate(180deg)" : "rotate(0deg)"}} /></div>
                  <div className='option' style={{visibility: iOpened ? "visible" : "hidden"}}>
                  <div className="column">
                    {institutions && institutions.map((el: institution) => (
                      <div className="row opcao" onClick={() => {
                        setInstitutionText(el.name);
                        setInstitution(el.pk_id);
                        setIOpened(false);
                      }}>
                        {el.name}
                      </div>
                    ))}
                  </div>
                  </div>
                </div>
                <input type="text" className='input' placeholder='Grad year*' onChange={(e) => setGradYear(e.target.value)}/>
              </div>
              <div className="column">
              <div className="node">
                  <div className='input2' onClick={() => setEOpened(!eOpened)}>{educationLevelText} <img src={arrow} className='arrow' style={{transform: eOpened ? "rotate(180deg)" : "rotate(0deg)"}} /></div>
                  <div className='option' style={{visibility: eOpened ? "visible" : "hidden"}}>
                  <div className="column">
                    {edLevels && edLevels.map((el: educationLevel) => (
                      <div className="row opcao" onClick={() => {
                        setEducationLevelText(el.name);
                        setEducationLevel(el.pk_id);
                        setEOpened(false);
                      }}>
                        {el.name}
                      </div>
                    ))
                    }
                  </div>
                  </div>
                </div>
                <div className="node">
                  <div className='input2' onClick={() => setTOpened(!tOpened)}>{typeText} <img src={arrow} className='arrow' style={{transform: tOpened ? "rotate(180deg)" : "rotate(0deg)"}}/></div>
                  <div className='option' style={{visibility: tOpened ? "visible" : "hidden"}}>
                    <div className="column">
                      {types && types.map((el: institutionType) => (
                        <div className="row opcao" onClick={() => {
                          setTypeText(el.name);
                          setEducationLevel(el.pk_id);
                          setTOpened(false);
                        }}>
                          {el.name}
                        </div>
                      ))}
                    </div>
                </div>
              </div>
            </div>
            </div>
          </>
        }/>}/>
        <Route path='/step2-2' element={<RegisterContainer previous='/step2' title='EDUCATION' img={step2} next='/step3' content={
          <>
          <div className="row">
          <div className='column'>
            <div className="node">
              <div className='input2' onClick={() => setIOpened(!iOpened)}>{institutionText} <img src={arrow} className='arrow' style={{transform: iOpened ? "rotate(180deg)" : "rotate(0deg)"}} /></div>
                <div className='option' style={{visibility: iOpened ? "visible" : "hidden"}}>
                <div className="column">
                  {institutions && institutions.map((el: institution) => (
                    <div className="row opcao" onClick={() => {
                      setInstitutionText(el.name);
                      setInstitution(el.pk_id);
                      setIOpened(false);
                    }}>
                      {el.name}
                    </div>
                  ))}
                </div>
                </div>
              </div>
            <input type="text" className="input" onChange={(e) => setGradYear(e.target.value)} placeholder='Grad year*'/>
            <input type="text" className="input" onChange={(e) => setPosition(e.target.value)} placeholder='Position*'/>
          </div>
          <div className='column'>
          <div className="node">
                  <div className='input2' onClick={() => setEOpened(!eOpened)}>{educationLevelText} <img src={arrow} className='arrow' style={{transform: eOpened ? "rotate(180deg)" : "rotate(0deg)"}} /></div>
                  <div className='option' style={{visibility: eOpened ? "visible" : "hidden"}}>
                  <div className="column">
                    {edLevels && edLevels.map((el: educationLevel) => (
                      <div className="row opcao" onClick={() => {
                        setEducationLevelText(el.name);
                        setEducationLevel(el.pk_id);
                        setEOpened(false);
                      }}>
                        {el.name}
                      </div>
                    ))
                    }
                  </div>
                  </div>
                </div>
            <input type="text" className="input" onChange={(e) => setDivision(e.target.value)} placeholder='Divison*'/>
            <input type="text" className="input" onChange={(e) => setORCID(e.target.value)} placeholder='ORCID*'/>
          </div>
          </div>
          </>
        }/>}/>

        <Route path='/step3' element={<RegisterContainer next='/home' title='INTERESTS' previous={'/step2'} img={step3} content={
          <>
            <p className='aaa' style={{fontWeight: "bold"}}>Select the categories you are interested in</p>
            <div className="terrorDaZN">
              {categories && categories.map((el: category) => (
                <>              
                  <a onClick={() => {
                    arr.includes(el.pk_id) ? arr.splice(arr.indexOf(el.pk_id), 1) : arr.push(el.pk_id)
                  }}><Category text={el.name} id={el.pk_id}></Category></a>
                </>
              ))}
            </div>
            <div onClick={() => {
              console.log('aasdas')
              register()
            }}>
              <a href='/home'><Button text='register'/></a>
            </div>
          </>
        }/>}/>
        <Route path='/home' element={<Home/>}/>
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;

