import './ArticlePreview.css';
import eye from '../assets/eye.png';

export default function ArticlePreview(props: {author: string, date: Date, title: string, views: number, img: string, desc: string}) {
    return (
        <div className="articlePreview" style={{backgroundColor: "#E8E8E8", width: "100%", padding: "15px 10px"}}>
            <header style={{display: 'flex', alignItems: 'center', height: "20%"}}>;
                <img src={props.img}/> 
                {props.author}
            </header>
            <article style={{display: 'flex', flexFlow: 'column nowrap', height: '60%'}}>
                <h2 className='purple'>{props.title}</h2>
                <p>{props.desc.substring(0, 150)}...</p>
            </article>
            <footer style={{display: 'flex', justifyContent: 'space-between',height: '20%'}}>
                <p className='purple'>{props.date.toString()}</p>
                <img src={eye} alt="eye" style={{height: '100%'}}/>
                <p className="purple">{props.views}</p>
            </footer>
        </div>
    )
}