import LoginContainer from '../LoginContainer/LoginContainer'
import './Login.css'
import logo from '../assets/bg.png'

export default function Login(props: {isLogin: boolean}) {
    return (
        <div className='login'>
            <div className="all">
                {props.isLogin ? <LoginContainer type='SIGN IN'/> : <LoginContainer type='SIGN UP'/>}
            </div>
            <img src={logo} alt="logo" className='bg' />
        </div>
    )
}