import create from 'zustand';
const user = create((set) => ({
    id: "",
    name: "",
    email: "",
    phone: "",
    cpf: "",
    password: "",
    fkEducation: "",
    city: "",
    state: "",
    student: "",
    ORCID: "",
    setStudent: (student) => set({student: student}),
    setId: (id) => set({id: id}),
    setName: (name) => set({name: name}), 
    setEmail: (email) => set({email: email}) ,
    setPhone: (phone) => set({phone: phone}) ,
    setCpf: (cpf) => set({cpf: cpf}) ,
    setFkEducation: (fkEducation) => set({fkEducation: fkEducation}),
    setState: (state) => set({state: state}),
    setCity: (city) => set({city: city}),
    setORCID: (ORCID) => set({ORCID: ORCID}),
    setPassword: (password) => set({password: password})
}))

export default user