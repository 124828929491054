import './RegisterContainer.css';
import bg from '../assets/bg2.png';
import logo from '../assets/scybook.png';
import step1 from '../assets/Steps1.png';
import step2 from '../assets/Steps2.png';
import step3 from '../assets/Steps3.png';

import { 
    ReactNode
 } from 'react';
import { useNavigate } from 'react-router';
import Button from '../Button/Button';

export default function RegisterContainer(props: {title: string, content: ReactNode, shouldAppear?: boolean, img: string, previous: string, next: string}) {

    let navigate = useNavigate();

    return (
        <div className='registerContainer'>
            <div className="registerDiv">
                {<p className='redirector' onClick={() => {navigate(props.previous)}}>back</p>}
                <div className="header">
                    <img src={logo} className='logo'/>
                    <p className="title">{props.title}</p>
                    <img src={props.img} className='steps'/>
                </div>
                {props.content}
                {props.shouldAppear !== false && props.title !== "INTERESTS" && <a onClick={() => {navigate(props.next)}}><Button text='NEXT'/></a>}
            </div>
            <img src={bg} alt='background' className='bg'/>
        </div>
    )
}