import './Header.css';
import logo from '../assets/scybook.png';
import iconNotice from '../assets/ICON - Notirficação.png'
import iconProfile from '../assets/ICON - Profile.png'
import iconLupa from '../assets/ICON - Lupa.png'

export default function Header() {

    return (
        <div className='headerMilla'>
            <a href="http://localhost:3000/home"><img src={logo} alt="profile picture"/></a>

            <div className='search'>
                <input type="text" className='caixa'/>
                <a href=""><img src={iconLupa} alt="icon lupa" /></a>
            </div>

            <nav>
            <a href=""><img src={iconNotice} alt="icon notice" className='img-nav' style={{margin: '0 12px 0 0'}}/></a>
            <a href=""><img src={iconProfile} alt="icon" className='img-nav'/></a>
            </nav>
        </div>
    )
}